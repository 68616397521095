body {
  background-color: rgb(172, 169, 169);
  font-family: Georgia, 'Times New Roman', Times, serif;
}

#headshot {
  width: 100%;
  object-fit: cover;
  margin: 0px;
}

.photoHeadline {
  width: 100%;
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:initial;
  margin-top: 20px
}

h1 {
  color: #212529;
}

.buffer {
  height: 30px;
}

.card {
  margin: 3%;
  margin-top: 2%;
  display: inline-flex;
  background-color: #212529;
  height: 475px;
}

.projectCards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.card-title {
  color: #fff;
}

.card-text {
  color: #fff;
}

.list-group-item {
  background-color: #212529;
}

.grayPlainLink {
  text-decoration: none;
  color: rgba(255,255,255,.55);
}

.grayPlainLink:hover {
  color:rgba(255,255,255,.75);
}

.card-img-top {
  padding: 4px;
}

.bodyP {
  text-align: left;
  margin-left: 20px;
  margin-right: auto;
}

.codingIcon {
  height: 70px;
  border-radius: 30%;
}

#ReactIcon {
  background-color: #20232A;
}

.skillsList {
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:space-evenly;
  padding: 20px;
  margin-right: 5%;
  margin-left: 5%;
}

.skillsListIcon {
  padding: 20px;
  list-style: none;
  flex-direction: row;
  text-align: center;
  font-weight: bold;
  font-size: .9rem;
}

.coding-languages {
  font-size: .75rem;
  color: #fff;
}

.plainLink {
  text-decoration: none;
  color: black;
}

.plainLink:hover {
  color: rgb(95, 12, 12);
}

.contactList {
  padding-top: 10px;
  margin-left: 20px;
}

.socialIcons {
  list-style: none;
  position: relative;
  display: flex;
  text-align: right;  
  align-items: center;
  justify-content:space-between;
  padding-right: 10px;
}

.sIcon {
  padding-right: 10px;
  padding-top:.5rem;
  height: 30px;
}

.dropdown-item {
  color: rgba(255,255,255,.55);
}

.dropdown-menu a:hover {
  background-color: #212529;
  color: rgba(255,255,255,.75);
}

.section {
  padding-top: 2%;
}

.bioList {
  padding-left: 10px;
}

.bioBullets {
  margin-left: 2%;
  list-style-position: inside;
}

footer {
  columns: 1;
  font-size: .75rem;
  background-color: #212529;
  color: #fff;
  padding: 10px;
  text-align: center;
  min-width: 100%;
}